import React from 'react'

export const Success = () => (
  <div>
    <h1 className="title">Success!</h1>
    <p>
      Check your console to see that your data has been
      <br />
      saved to session storage under userSignUps.
    </p>
  </div>
)
