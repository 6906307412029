import validate from 'validate.js'

const validationRules = {
  username: {
    presence: true,
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
    },
  },
  confirmPassword: { equality: 'password' },
}

export const validateSignUpModel = (signUpModel) =>
  validate(signUpModel, validationRules)
