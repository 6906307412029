export const saveUserSignUp = (signUpModel) => {
  sessionStorage.setItem('userSignUps', JSON.stringify(signUpModel))
  console.log(
    `${JSON.stringify(
      signUpModel
    )} has been saved to session storage under userSignUps.`
  )
}
export const getSavedUserSignUpData = () =>
  sessionStorage.getItem('userSignUps')
