import React from 'react'
import { observer } from 'mobx-react'
import { FormField } from './FormField'
import { FormContext } from './FormContext'

export const SignUpForm = observer(({ signUpFormModel }) => (
  <FormContext.Provider value={signUpFormModel}>
    <h1 className="title">Sign Up</h1>
    <form>
      <FormField fieldName="username" label="Username / Email" type="email" />
      <FormField fieldName="password" label="Password" type="password" />
      <FormField
        fieldName="confirmPassword"
        label="Confirm Password"
        type="password"
      />
      <button
        className="button is-link"
        type="submit"
        onClick={(event) => {
          event.preventDefault()
          signUpFormModel.submitForm()
        }}
      >
        Submit
      </button>
      {/*
      <h2>Current model state:</h2>
      <pre>{JSON.stringify(signUpFormModel.model)}</pre>
      */}
    </form>
  </FormContext.Provider>
))
