import { observable, action, toJS } from 'mobx'

import { SignUpModel } from './SignUpModel'
import { validateSignUpModel } from './SignUpValidator'
import { saveUserSignUp } from './SignUpPersistence'

export const SignUpFormModel = () =>
  observable(
    {
      model: SignUpModel(),
      displayValidationMessages: false,

      formState: 'form', // form, success

      get validationMessages() {
        return validateSignUpModel(this.model)
      },
      get hasValidationMessages() {
        return (
          Boolean(this.validationMessages) &&
          Object.keys(this.validationMessages).length > 0
        )
      },
      get showValidationMessages() {
        return this.displayValidationMessages && this.hasValidationMessages
      },
      get debugView() {
        return toJS(this)
      },
      updateModel(field, value) {
        this.model[field] = value
      },
      submitForm() {
        this.displayValidationMessages = true
        if (!this.hasValidationMessages) {
          saveUserSignUp(this.model)
          this.formState = 'success'
        }
      },
    },
    {
      updateModel: action.bound,
      submitForm: action,
    }
  )
