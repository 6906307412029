import React from 'react'
import { SignUpFormModel } from './models/SignUpFormModel'
import { SignUpForm } from './components/SignUpForm'
import { Success } from './components/SignUpSuccess'
import { observer } from 'mobx-react'

const signUpFormModel = SignUpFormModel()
global.signUpFormModel = signUpFormModel

export const App = observer(() => {
  let content
  switch (signUpFormModel.formState) {
    case 'success':
      content = <Success />
      break
    default:
      content = <SignUpForm signUpFormModel={signUpFormModel} />
      break
  }
  return <div className="App has-background-white">{content}</div>
})
