import React, { useContext } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'

import { FormContext } from './FormContext'

// note that this returns a function that is the changeHandler
const onChangeHandler = (formModel) => (event) => {
  formModel.updateModel(event.target.name, event.target.value)
}

export const FormField = observer(({ fieldName, label, type = 'text' }) => {
  const formModel = useContext(FormContext)

  const showFieldError =
    formModel.showValidationMessages &&
    Boolean(formModel.validationMessages[fieldName])

  let validationMessage = null
  let ariaInputAttributes = {}
  if (showFieldError) {
    ariaInputAttributes = {
      'aria-invalid': true,
      'aria-describedby': `error_${fieldName}`,
    }
    validationMessage = (
      <p
        className="help is-danger"
        id={ariaInputAttributes['aria-describedBy']}
      >
        {formModel.validationMessages[fieldName]}
      </p>
    )
  }

  return (
    <div className="field field--variant1">
      <label className="label has-text-grey-dark" htmlFor={fieldName}>
        {label}
      </label>
      <div className="control">
        <input
          className={classnames('input', { 'is-danger': showFieldError })}
          type={type}
          name={fieldName}
          id={fieldName}
          value={formModel.model[fieldName]}
          onChange={onChangeHandler(formModel)}
          {...ariaInputAttributes}
        />
      </div>
      {validationMessage}
    </div>
  )
})
